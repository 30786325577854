exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bingo-js": () => import("./../../../src/pages/bingo.js" /* webpackChunkName: "component---src-pages-bingo-js" */),
  "component---src-pages-dreidel-coin-js": () => import("./../../../src/pages/dreidel/coin.js" /* webpackChunkName: "component---src-pages-dreidel-coin-js" */),
  "component---src-pages-dreidel-game-js": () => import("./../../../src/pages/dreidel/game.js" /* webpackChunkName: "component---src-pages-dreidel-game-js" */),
  "component---src-pages-dreidel-index-js": () => import("./../../../src/pages/dreidel/index.js" /* webpackChunkName: "component---src-pages-dreidel-index-js" */),
  "component---src-pages-dreidel-scoreboard-js": () => import("./../../../src/pages/dreidel/scoreboard.js" /* webpackChunkName: "component---src-pages-dreidel-scoreboard-js" */),
  "component---src-pages-hebrew-bingo-js": () => import("./../../../src/pages/hebrew-bingo.js" /* webpackChunkName: "component---src-pages-hebrew-bingo-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lessons-index-js": () => import("./../../../src/pages/lessons/index.js" /* webpackChunkName: "component---src-pages-lessons-index-js" */),
  "component---src-pages-tags-js": () => import("./../../../src/pages/tags.js" /* webpackChunkName: "component---src-pages-tags-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

